import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierRoadster = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierRoadster {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { regex: "/Roadster/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Roadster Watches for Sale'}
      canonical={'/fine-watches/cartier/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Roadster Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/filter/model/miss-pasha,pasha,pasha-35,pasha-38mm,pasha-42mm,pasha-chronograph/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-roadster-header.png"
                alt="Pre-Owned Certified Used Cartier Roadster Header"
                aria-label="Used Cartier Roadster Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTIER ROADSTER</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE AND MODERN CARTIER ROADSTER
              WATCHES AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER ROADSTER WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              We are a real watch and jewelry store located in Surfside, Miami. We're located across
              from the famous Bal Harbour Shops, with real watch and jewelry experts on-site, six
              days a week. We have a website but we're not a virtual store. Our customers always say
              we are Miami's #1 used watch buyer and seller. We've proudly assisted customers for
              the past 42 years. Gray and Sons are sellers and buyers of pre-owned Cartier Roadster
              watches in Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal Harbour,
              Surfside, Sunny Isles , Aventura, Fort Lauderdale and many more areas.
              <br />
              <br />
              Cartier Roadster first debuted in 2001, which means it is one of the youngest Cartier
              watch collections. To compare, Cartier Tank was presented in 1917, and Cartier Santos
              was presented in 1904. The Roadster collection was stopped in 2012, but luckily, the
              watches are still available in the second-hand luxury watch market.
              <br />
              <br />
              You can now buy a pre-owned Cartier Roadster Watch for the best price at Gray &amp;
              Sons, the top luxury watch seller with a vast inventory of pre-owned Cartier watches,
              fine Swiss watches, and a large, in-stock inventory of Rolex watches. If you’re
              looking for a certified authentic pre-owned Cartier watch, including Cartier Roadster,
              Cartier Ballon Bleu, Cartier Pasha, diamond Cartier watches with diamond bezel, or
              Rolex, Omega, and Patek Philippe, you’re in the right place to buy the best priced
              watches. We offer certified authentic and certfied pre-owned watches.
              <br />
              <br />
              Popular Cartier Roadster Models
              <br />
              <ul>
                <li>-Cartier Roadster Ref. W62001V3 Automatic in Stainless Steel </li>
                <li>-Cartier Roadster Ref. W62005V2 Automatic in Yellow Gold</li>
                <li>Cartier Roadster Ref. 2826 Chronograph Automatic in White Gold</li>
                <li>-Cartier Roadster Ref. 2619 Chronograph Automatic in Yellow Gold</li>
                <li>-Cartier Roadster Ref. 3103 Automatic in Rose Gold</li>
              </ul>
              <br />
              <br />
              All watches available at Gray &amp; Sons have been recently serviced, completely
              lubricated, timing adjusted, and detailed. Used Cartier watches from Gray &amp; Sons
              come with our exclusive in-house one-year warranty or a two-year warranty for modern
              Rolex watches. Do you have any questions about our process or about Cartier
              wristwatches and other luxury watches we offer? Contact a representative for further
              assistance on &ensp;
              <a href="/">www.grayandsons.com</a>, or visit Gray &amp; Sons’ Showroom in Surfside,
              Miami where you can meet one of our six highly qualified in-house watchmakers. Gray
              &amp; Sons is a professional luxury watch repair center offering full-service and
              Cartier watch battery replacement. We are independent Cartier watch dealers allowing
              for price flexibility and inventory control. Chat now with our specialists Rich and
              Vika via this link &ensp;
              <a href="/chat-with-rich">www.grayandsons.com/chat-with-rich</a>
              and make the best deal with our decision-makers.
              <br />
              <br />
              Gray and Sons is also the top-rated place to sell your watch Miami and sell your
              jewelry Miami. If you’re thinking where can I sell my watch or where can I sell my
              jewelry or looking for a Rolex buyer near me, visit &ensp;
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com</a> Sell Us Your
              Jewelry is the buying division of Gray &amp; Sons, the online jewelry buyer, and
              online watch buyer. As the best jewelry buyer and the best watch buyer, Sell Us Your
              Jewelry offers the highest prices in the shortest time for all Cartier models. Wait no
              more. Sell your watch and sell your jewelry for cash today.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierRoadster
